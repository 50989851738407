/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import { format } from 'date-fns';
import { GetVehicle, typeVehicleList, EditVehicle } from '../../services/vehicules';
import { getBuildingsList } from '../../services/structures';
import useAppContext from '../../store/useAppContext';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';

function EditVehicule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const context = useAppContext();
  const { page } = useTableFiltersPagination({ initialFilters: {} });

  const NombrePneu = [
    { label: '4', value: 4 },
    { label: '6', value: 6 },
    { label: '8', value: 8 },
    { label: '10', value: 10 },
  ];

  const Energy = [
    { label: 'Diesel', value: 'diesel' },
    { label: 'Essence', value: 'essence' },
    { label: 'Éthanol', value: 'ethanol' },
    { label: 'Hydride', value: 'hydride' },
    { label: 'Électrique', value: 'electrique' },
    { label: 'Hydrogène', value: 'hydrogene' },
    { label: 'GPL', value: 'gpl' },
  ];

  const Critair = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: 'Non classés', value: 'unclassified' },
  ];

  const FuncVehicle = [
    { label: 'Véhicule de service', value: 'service' },
    { label: 'Véhicule de fonction', value: 'function' },
  ];

  const mapForPneu = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: item.label,
      value: item.value,
    }))
    : []);

  const goBackUrl = (msg) => {
    navigate('/vehicule');
    toast.success(msg);
  };

  const putContact = useMutation(EditVehicle, {
    onSuccess: () => {
      goBackUrl(t('contract.succes_save_modif'));
    },
    onError: () => {
      toast.error(t('contract.erreur_save_modif'));
    },
  });

  const initialValues = {
    licensePlate: '',
    buildingId: '',
    vehicleTypeId: '',
    brand: '',
    model: '',
    currentMileage: '',
    firstRegistrationDate: '',
    lastMaintenanceMileage: null,
    tireNumber: '',
    lastControlDate: '',
    lastMaintenanceDate: '',
    serviceIntervalMonths: '',
    serviceIntervalMileage: '',
    hasWinterTires: true,
    hasTailgate: true,
    isActive: false,
    critair: '',
    function: '',
    energy: '',
    assignment: '',
    energyCard: '',
  };
  const validationSchema = Yup.object({
    licensePlate: Yup.string(),
    buildingId: Yup.string(),
    vehicleTypeId: Yup.string(),
    brand: Yup.string(),
    model: Yup.string(),
    currentMileage: Yup.string(),
    firstRegistrationDate: Yup.date(),
    lastMaintenanceMileage: Yup.number().nullable(),
    tireNumber: Yup.number(),
    lastControlDate: Yup.date(),
    lastMaintenanceDate: Yup.string(),
    serviceIntervalMonths: Yup.string(),
    serviceIntervalMileage: Yup.string(),
    hasTailgate: Yup.bool(),
    hasWinterTires: Yup.bool(),
    isActive: Yup.bool(),
    critair: Yup.string(),
    function: Yup.string(),
    energy: Yup.string(),
    assignment: Yup.string(),
    energyCard: Yup.string().max(30, t('vehicule.energyCard_max')),
    rearAxle: Yup.string().nullable(),
    frontAxle: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const config = {
        ...values,
        vehiculeId: urlParams.id,
      };
      putContact.mutate(config);
    },
  });

  const typeVehicleListMutate = useQuery(['typeVehicule', page], () => typeVehicleList({
    page: 0,
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }), {
    onSuccess: () => {
      if (typeVehicleListMutate.data?.data?.vehicleTypes?.length === 0) {
        toast.error(t('vehicule.error_type_vehicule'));
      }
    },
  });

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: `${item.type} - ${item.label}`,
      value: item.id,
    }))
    : []);

  const getBuildingList = useQuery(['buildingList'], () => getBuildingsList({
    page: 1,
    establishmentId: context[0]?.choiceEstablishment?.id,
    bookletId: context[0]?.choiceBooklet,
  }));

  const GetVehicleMutate = useMutation(GetVehicle, {
    onSuccess: (res) => {
      formik.setFieldValue('licensePlate', res?.data?.licensePlate);
      formik.setFieldValue('buildingId', res?.data?.buildingId);
      formik.setFieldValue('vehicleTypeId', res?.data?.vehicleTypeId);
      formik.setFieldValue('brand', res?.data?.brand);
      formik.setFieldValue('model', res?.data?.model);
      formik.setFieldValue('currentMileage', res?.data?.currentMileage);
      formik.setFieldValue('firstRegistrationDate', format(new Date(res?.data?.firstRegistrationDate?.date), 'yyyy-MM-dd') || '');
      formik.setFieldValue('lastMaintenanceMileage', res?.data?.lastMaintenanceMileage);
      formik.setFieldValue('tireNumber', res?.data?.tireNumber);
      formik.setFieldValue('serviceIntervalMonths', res?.data?.serviceIntervalMonths);
      formik.setFieldValue('serviceIntervalMileage', res?.data?.serviceIntervalMileage);
      formik.setFieldValue('hasWinterTires', res?.data?.hasWinterTires);
      formik.setFieldValue('hasTailgate', res?.data?.hasTailgate);
      formik.setFieldValue('isActive', res?.data?.isActive);
      formik.setFieldValue('critair', res?.data?.critair);
      formik.setFieldValue('function', res?.data?.function);
      formik.setFieldValue('energy', res?.data?.energy);
      formik.setFieldValue('assignment', res?.data?.assignment);
      formik.setFieldValue('energyCard', res?.data?.energyCard);
      formik.setFieldValue('rearAxle', res?.data?.rearAxle);
      formik.setFieldValue('frontAxle', res?.data?.frontAxle);
      // le laisser en dernier si il est null il ne bloque pas les autres
      formik.setFieldValue('firstRegistrationDate', res?.data?.lastMaintenanceDate?.date ? format(new Date(res?.data?.firstRegistrationDate?.date), 'yyyy-MM-dd') : '');
      formik.setFieldValue('lastMaintenanceDate', res?.data?.lastMaintenanceDate?.date ? format(new Date(res?.data?.lastMaintenanceDate?.date), 'yyyy-MM-dd') : '');
      formik.setFieldValue('lastControlDate', res?.data?.lastControlDate?.date ? format(new Date(res?.data?.lastControlDate?.date), 'yyyy-MM-dd') : '');
    },
  });

  useEffect(() => {
    GetVehicleMutate.mutate(urlParams.id);
  }, []);

  const mapForSelectBulding = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);
  return (
    <Layout
      title={t('vehicule.modifvehicule')}
      layout="form"
      table={GetVehicleMutate.data}
      queryError={
        GetVehicleMutate?.error
        || getBuildingList?.error
        || typeVehicleListMutate?.error
        || putContact?.error
      }
      loading={GetVehicleMutate.isLoading && getBuildingList.isLoading}
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('vehicule.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('vehicule.modifvehicule')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm" loading={GetVehicleMutate.isLoading}>
        <div className="form_group">
          <Input
            label={t('vehicule.licensePlate')}
            id="licensePlate"
            name="licensePlate"
            type="text"
            placeholder={t('vehicule.licensePlate')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.licensePlate}
          />
          {formik.touched.licensePlate && formik.errors.licensePlate && (
            <div className="form_error">{formik.errors.licensePlate}</div>
          )}
        </div>
        {getBuildingList?.data?.data?.buildings?.length > 1 && (
        <div className="form_group">
          <Select
            label={t('vehicule.batiment')}
            id="batiment"
            name="batiment"
            type="text"
            defaultValue={formik.values.buildingId}
            placeholder={formik.values.buildingId}
            onChange={(value) => formik.setFieldValue('buildingId', value.value)}
            onBlur={formik.handleBlur}
            options={mapForSelectBulding(getBuildingList?.data?.data?.buildings)}
            value={
              mapForSelectBulding(getBuildingList?.data?.data?.buildings).find(
                (item) => item.value === formik.values.buildingId,
              )
            }
            loading={getBuildingList.isLoading}
          />
          {formik.touched.batiment && formik.errors.batiment ? (
            <div className="form_error">{formik.errors.batiment}</div>
          ) : null}
        </div>
        )}
        <div className="form_group">
          <Select
            label={t('vehicule.type_vehicule')}
            id="vehicleTypeId"
            name="vehicleTypeId"
            type="select"
            onChange={(value) => formik.setFieldValue('vehicleTypeId', value.value)}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.vehicleTypeId}
            options={mapForSelect(typeVehicleListMutate?.data?.data.vehicleTypes)}
            value={mapForSelect(typeVehicleListMutate?.data?.data.vehicleTypes).find(
              (item) => item.value === formik.values.vehicleTypeId,
            ) || formik.values.vehicleTypeId}
            loading={typeVehicleListMutate.isLoading}
          />
          {formik.touched.vehicleTypeId && formik.errors.vehicleTypeId ? (
            <div className="form_error">{formik.errors.vehicleTypeId}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.marque')}
            id="marque"
            name="brand"
            type="text"
            placeholder={t('vehicule.marque')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.brand}
          />
          {formik.touched.brand && formik.errors.brand ? (
            <div className="form_error">{formik.errors.brand}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.model')}
            id="model"
            name="model"
            type="text"
            placeholder={t('vehicule.model')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.model}
          />
          {formik.touched.model && formik.errors.model ? (
            <div className="form_error">{formik.errors.model}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('addVehicule.type_carbu')}
            id="energy"
            name="energy"
            type="select"
            onChange={(value) => formik.setFieldValue('energy', value.value)}
            onBlur={formik.handleBlur}
            options={Energy}
            value={
              mapForPneu(Energy).find(
                (item) => item.value === formik.values.energy,
              )
            }
            required
            valueInput={formik.values.energy}
          />
          {formik.touched.energy && formik.errors.energy ? (
            <div className="error">{formik.errors.energy}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('addVehicule.vignette_critair')}
            id="critair"
            name="critair"
            type="select"
            onChange={(value) => formik.setFieldValue('critair', value.value)}
            onBlur={formik.handleBlur}
            options={Critair}
            value={
              mapForPneu(Critair).find(
                (item) => item.value === formik.values.critair,
              )
            }
            required
            valueInput={formik.values.critair}
          />
          {formik.touched.critair && formik.errors.critair ? (
            <div className="error">{formik.errors.critair}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('addVehicule.fonction_vehicule')}
            id="function"
            name="function"
            type="select"
            onChange={(value) => formik.setFieldValue('function', value.value)}
            onBlur={formik.handleBlur}
            options={FuncVehicle}
            value={
              mapForPneu(FuncVehicle).find(
                (item) => item.value === formik.values.function,
              )
            }
            required
            valueInput={formik.values.function}
          />
          {formik.touched.function && formik.errors.function ? (
            <div className="error">{formik.errors.function}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('addVehicule.affectation_vehicule')}
            id="assignment"
            name="assignment"
            type="text"
            placeholder={t('addVehicule.affectation_vehicule')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.assignment}
          />
          {formik.touched.assignment && formik.errors.assignment ? (
            <div className="error">{formik.errors.assignment}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.kilometrageactuel')}
            id="currentMileage"
            name="currentMileage"
            type="text"
            placeholder={t('vehicule.kilometrageactuel')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentMileage}
            title="La modification du kilométrage doit être réalisé dans les saisies"
            disabled
          />
          {formik.touched.currentMileage && formik.errors.currentMileage ? (
            <div className="form_error">{formik.errors.currentMileage}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.date_premiere_mise_circulation')}
            id="firstRegistrationDate"
            name="firstRegistrationDate"
            type="date"
            value={formik.values.firstRegistrationDate}
            onChange={formik.handleChange}
          />
          {formik.touched.firstRegistrationDate && formik.errors.firstRegistrationDate ? (
            <div className="form_error">{formik.errors.firstRegistrationDate}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('addVehicule.first_controle')}
            id="lastControlDate"
            name="lastControlDate"
            type="date"
            placeholder={t('addVehicule.first_controle')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastControlDate || ''}
          />
          {formik.touched.lastControlDate && formik.errors.lastControlDate ? (
            <div className="error">{formik.errors.lastControlDate}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.kilometrage_dernier_entretien')}
            id="lastMaintenanceMileage"
            name="lastMaintenanceMileage"
            type="text"
            placeholder={t('vehicule.kilometrage_dernier_entretien')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastMaintenanceMileage || ''}
          />
          {formik.touched.lastMaintenanceMileage && formik.errors.lastMaintenanceMileage ? (
            <div className="form_error">{formik.errors.lastMaintenanceMileage}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('vehicule.nmb_pneu')}
            id="nmb_pneu"
            name="tireNumber"
            type="select"
            onChange={(value) => formik.setFieldValue('tireNumber', value.value)}
            onBlur={formik.handleBlur}
            options={mapForPneu(NombrePneu)}
            value={
              mapForPneu(NombrePneu).find(
                (item) => item.value === formik.values.tireNumber,
              )
            }
            required
            valueInput={formik.values.tireNumber}
          />
          {formik.touched.tireNumber && formik.errors.tireNumber ? (
            <div className="form_error">{formik.errors.tireNumber}</div>
          ) : null}
        </div>
        {formik.values.tireNumber !== 4 ? null : (
          <>
            <div className="form_group">
              <Input
                label={t('vehicule.rearAxle')}
                id="rearAxle"
                name="rearAxle"
                type="text"
                placeholder={t('vehicule.rearAxlesExemple')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rearAxle}
              />
              {formik.touched.rearAxle && formik.errors.rearAxle ? (
                <div className="error">{formik.errors.rearAxle}</div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                label={t('vehicule.frontAxle')}
                id="frontAxle"
                name="frontAxle"
                type="text"
                placeholder={t('vehicule.frontAxlesExemple')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.frontAxle}
              />
              {formik.touched.frontAxle && formik.errors.frontAxle ? (
                <div className="error">{formik.errors.frontAxle}</div>
              ) : null}
            </div>
          </>
        )}
        <div className="form_group">
          <Input
            label={t('vehicule.date_dernire_revision')}
            id="lastMaintenanceDate"
            name="lastMaintenanceDate"
            type="date"
            placeholder={t('vehicule.date_derniere_revision')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastMaintenanceDate}
          />
          {formik.touched.lastMaintenanceDate && formik.errors.lastMaintenanceDate ? (
            <div className="form_error">{formik.errors.lastMaintenanceDate}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.periodicite__km_revision')}
            id="serviceIntervalMileage"
            name="serviceIntervalMileage"
            type="number"
            placeholder={t('vehicule.periodicite__km_revision')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.serviceIntervalMileage}
          />
          {formik.touched.serviceIntervalMileage && formik.errors.serviceIntervalMileage ? (
            <div className="form_error">{formik.errors.serviceIntervalMileage}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.periodicite__mois_revision')}
            id="serviceIntervalMonths"
            name="serviceIntervalMonths"
            type="number"
            placeholder={t('vehicule.periodicite__mois_revision')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.serviceIntervalMonths}
          />
          {formik.touched.serviceIntervalMonths && formik.errors.serviceIntervalMonths ? (
            <div className="form_error">{formik.errors.serviceIntervalMonths}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.energyCard')}
            id="energyCard"
            name="energyCard"
            type="text"
            placeholder={t('vehicule.energyCard')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.energyCard}
          />
          {formik.touched.energyCard && formik.errors.energyCard ? (
            <div className="error">{formik.errors.energyCard}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Checkbox
            label={t('vehicule.hasWinterTires')}
            id="hasWinterTires"
            name="hasWinterTires"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('hasWinterTires', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.hasWinterTires === true}
            value={formik.values.hasWinterTires ? true : false}
          />
          {formik.touched.hasWinterTires && formik.errors.hasWinterTires ? (
            <div className="form_error">{formik.errors.hasWinterTires}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Checkbox
            label={t('vehicule.hasTailgate')}
            id="hasTailgate"
            name="hasTailgate"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('hasTailgate', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.hasTailgate === true}
            value={formik.values.hasTailgate ? true : false}
          />
          {formik.touched.hasTailgate && formik.errors.hasTailgate ? (
            <div className="form_error">{formik.errors.hasTailgate}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Checkbox
            label={t('vehicule.active')}
            id="isActive"
            name="isActive"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('isActive', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.isActive === true}
            value={formik.values.isActive ? true : false}
          />
          {formik.touched.isActive && formik.errors.isActive ? (
            <div className="error">{formik.errors.isActive}</div>
          ) : null}
        </div>
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('vehicule.edit')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('vehicule.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default EditVehicule;
